<template>
  <div class="message">
    <div
      class="message__container col-11 col-sm-10 m-auto"
      data-aos="fade-up"
      data-aos-duration="1000"
    >
      <div class="message__content">
        <img :src="messageIcon" alt="mess-icon" />
        <h2>{{ title }}</h2>
        <div>
          <p>{{ sub.first }}</p>
          <p>{{ sub.second }}</p>
          <p style="margin-bottom: 0">{{ sub.third }}</p>
        </div>
        <h3>{{ acthor }}</h3>
        <router-link to="/ceo" style="text-decoration: none">
          <!-- <button
            type="button"
            class="btn btn-primary text font-weight-bold see-all d-flex align-items-center"
            style="
              margin-top: 35px;
              background: #002a9c;
              border-radius: 40px;
              padding: 14px 28px;
              margin: 0;
              width: max-content;
              border: none;
              gap: 10px;
            "
          >
            <span>{{ btnView }}</span>
          </button> -->
          <button
            type="button"
            class="btn btn-primary text d-flex align-items-center btn-see-more"
          >
            {{ btnView }}
            <svg
              width="18"
              height="12"
              viewBox="0 0 20 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="services__item__detail-img"
            >
              <path
                d="M1.33301 7C0.780723 7 0.333008 7.44772 0.333008 8C0.333008 8.55228 0.780723 9 1.33301 9V7ZM19.0123 8.70711C19.4029 8.31658 19.4029 7.68342 19.0123 7.29289L12.6484 0.928932C12.2579 0.538408 11.6247 0.538408 11.2342 0.928932C10.8436 1.31946 10.8436 1.95262 11.2342 2.34315L16.891 8L11.2342 13.6569C10.8436 14.0474 10.8436 14.6805 11.2342 15.0711C11.6247 15.4616 12.2579 15.4616 12.6484 15.0711L19.0123 8.70711ZM1.33301 9H18.3052V7H1.33301V9Z"
                fill="white"
              />
            </svg>
          </button>
        </router-link>
      </div>
      <div class="message__block">
        <img v-lazy="ceoImage" alt="ceo" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Message',
  data() {
    return {
      title: this.$t('about_us').message.title,
      sub: this.$t('about_us').message.sub,
      acthor: this.$t('about_us').message.acthor,
      btnView: this.$t('about_us').message.btnView,
      messageIcon: '/images/icons/aboutus/message.svg',
      ceoImage: '/images/aboutus/about-ceo.png'
    };
  },
  watch: {
    lang(val) {
      this.$i18n.locale = val;
      this.title = this.$t('about_us').message.title;
      this.sub = this.$t('about_us').message.sub;
      this.acthor = this.$t('about_us').message.acthor;
      this.btnView = this.$t('about_us').message.btnView;
    }
  },
  computed: {
    ...mapState({
      lang: (state) => state.langs.lang
    })
  }
};
</script>

<style lang="scss" scroped>
.message {
  margin-top: -50px;
  padding-top: 50px;
  position: relative;
  width: 100%;
  background-color: #221e83;
  .message__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5%;
    height: 100%;
    margin: 0 auto;
  }
  .message__content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 72px 0;
    padding-top: 100px;
    max-width: 780px;
    color: #fff;
    font-size: 18px;
    img {
      width: 54px;
    }
    h2 {
      font-weight: 700;
      font-size: 40px;
    }
    h3 {
      font-weight: 600;
      font-size: 18px;
      text-transform: uppercase;
    }
  }
  .message__block {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    z-index: 0;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 50%;
      height: 100%;
      background-color: #221e83;
      z-index: -1;
    }
    img {
      width: 500px;
    }
  }
  .btn-see-more {
    background: #fc7735;
    border-radius: 40px;
    padding: 10px 28px;
    width: max-content;
    border: none;
    gap: 8px;
  }

  .btn-see-more:hover {
    opacity: 0.8;
    transition: 0.3s;
  }
  @media (min-width: 1500px) {
    .message__container {
      justify-content: center;
    }
  }
  @media (max-width: 1024px) {
    margin-top: 0px;
    padding-top: 0px;
    .message__container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 80%;
      height: 100%;
      margin: 0 auto;
      flex-direction: column-reverse;
      padding: 100px 0;
      padding-top: 150px;
    }
    .message__content {
      padding: 0;
    }
    .message__block {
      background-color: unset;
      img {
        width: 300px;
      }
    }
  }
  @media (max-width: 768px) {
    .message__content h2 {
      font-size: 32px;
    }
  }
}
</style>
